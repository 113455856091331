$(function () {
  // Scroll Hint
  new ScrollHint('.table-scroll-wrapper', {
    i18n: {
      scrollable: 'スクロールできます',
    },
  });

  // menu
  $('.common-nav-list__item > i.sub-trigger').on('click', function () {
    const that = $(this).parent();
    if (!that.hasClass('on')) {
      that.addClass('on');
      that.find('.common-nav-sub-list').stop().slideDown();
    } else {
      that.removeClass('on');
      that.find('.common-nav-sub-list').stop().slideUp();
    }
    return false;
  });
});
